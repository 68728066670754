html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}
